<template>
  <Navigation :index="7"></Navigation>
  <!-- main-wrapper start -->
  <div class="main-wrapper">

    <el-tabs tab-position="left" v-model="ActiveItem" @tab-click="handleClick" :style="{ height: clientHeight - 70 + 'px' }">
      <el-tab-pane label="基本信息" name="goodsInfo">

        <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-col :span="4">
              <el-form-item label="商品名称">
                <el-input></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="商品价格">
                <el-input-number></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="成本价">
                <el-input-number></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="商品分类">

                <el-select placeholder="请选择商品分类">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>

              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="商品类型">
                <el-select placeholder="请选择商品类型">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">

            </el-col>
           
          </el-row> 
          <hr /> 
          <el-row>
            <el-col :span="12">
              <el-form-item label="封面图">

                <PictureCover v-model="ProductOpt.Images" v-model:ImageFormet="ProductOpt.ImagesFormet"
                  v-model:ImageModel="ProductOpt" />
 
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

      </el-tab-pane>
      <el-tab-pane label="SKU" name="SKU">
        
        <el-row>
            <el-form-item label="选择属性">

              <el-select style="width: 150px;" v-model="value" placeholder="请选择">
                <el-option v-for="item in AttributeOptions" :key="item.Id" :label="item.Name" :value="item.Id">
                </el-option>
              </el-select>
           
            </el-form-item>
          </el-row>


      </el-tab-pane>
      <el-tab-pane label="图片管理" name="second">配置管理</el-tab-pane>
      <el-tab-pane label="详细信息" name="third">角色管理</el-tab-pane>

    </el-tabs>



  </div>
</template>
<script >
import axios from "../../../../commons/AxiosMethod.js"

import { ElMessageBox, ElMessage } from "element-plus"
import Navigation from "../../../../components/Modules/Navigation.vue"
import PictureCover from "../../../../components/PictureCover.vue"
export default {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types aboutus
  data() {
    return {

      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,

      ActiveItem: 'goodsInfo',
      ProductOpt: {},
      AttributeOptions: [{
        Name: '颜色', Id: 66
      }, {
        Name: '内存', Id: 677
      }]
    }
  },
  name: "App",
  mounted() {

  },

  components: {
    PictureCover,
    Navigation,
  },
  methods: {


    GetHomeAdverts() {
      const param = {}
      axios.apiMethod(
        "/shoppings/Advert/GetHomeAdverts",
        "get",
        {},
        (result) => {
          if (result.Code == 200) {
            this.ProductNews = result.Data.NewsProduct
            this.ProductCutprice = result.Data.CutpriceProduct
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.main-wrapper {
  padding: 5px;
}
</style>
